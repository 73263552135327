<template>
  <div class="coming-soon">
    <div class="content">
      <div class="logo">
        <IconComponent name="aslLogo" class="logo-icon"/>
        <h2>IOTICS</h2>
      </div>
      <div class="position_images">
        <IconComponent name="img1" class="img-1 res"/>
        <IconComponent name="img2" class="img-2 res"/>
        <IconComponent name="img3" class="img-3 res"/>
        <IconComponent name="logo" class="img-4 res"/>
        <IconComponent name="img5" class="img-5 res"/>
        <IconComponent name="img6" class="img-6 res"/>
        <img src="./assets/image33.png" alt="" class="img-7">
        <img src="./assets/image34.png" alt="" class="img-8">
      </div>
      <h1>Скоро...</h1>
      <p>Оставайтесь с нами для новых открытий!</p>
      <div class="countdown">
        <div class="time-unit">
          <span>{{ formatTime(timeLeft.days) }}</span> дней
        </div>
        <span class="line"></span>
        <div class="time-unit">
          <span>{{ formatTime(timeLeft.hours) }}</span> часов
        </div>
        <span class="line"></span>
        <div class="time-unit">
          <span>{{ formatTime(timeLeft.minutes) }}</span> минут
        </div>
        <span class="line"></span>
        <div class="time-unit">
          <span>{{ formatTime(timeLeft.seconds) }}</span> секунд
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from './icons.vue'

export default {
  components: {
    IconComponent
  },
  data() {
    return {
      targetDate: new Date("2024-12-31T00:00:00"),
      timeLeft: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000);
  },
  methods: {
    updateTime() {
      const now = new Date();
      const distance = this.targetDate - now;

      if (distance < 0) {
        this.timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        return;
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      this.timeLeft = { days, hours, minutes, seconds };
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    }
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.img-1, .img-2, .img-3, .img-4, .img-5, .img-6 {
  fill: linear-gradient(180deg, #D6D6D6 0%, #4F4F4F 100%);
}
.img-1 {
  position: fixed;
  top: 56px;
  right: 627px;
  opacity: 0.4;
}
.img-2 {
  position: fixed;
  top: -12px;
  right: 300px;
  opacity: 0.4;
}
.img-3 {
  position: fixed;
  top: 225px;
  right: 495px;
  opacity: 0.4;
}
.img-4 {
  position: fixed;
  top: 460px;
  right: 770px;
  opacity: 0.7;
}
.img-5 {
  position: fixed;
  bottom: 30px;
  left: 330px;
  opacity: 0.2;
}
.img-6 {
  position: fixed;
  bottom: 20px;
  left: 540px;
  opacity: 0.2;
}
.img-7 {
  position: fixed;
  top: 0;
  left: 0;
}
.img-8 {
  position: fixed;
  bottom: 0;
  left: 0;
}
*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Manrope;
}
body {
  width: 100%;
  height: 100%;
}
.logo {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;
  position: absolute;
  top: 24px;
  left: 0;
}
.logo > h2 {
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 1.44px;
}
.coming-soon {
  width: 100%;
  height: 100vh;
  background: url('./assets/image 32.png');
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
}
.content {
  position: relative;
  width: 1240px;
  height: 100%;
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
}
.content > h1 {
  color: #FFF;
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 4.8px;
  margin-top: 236px;
}
.content > p {
  color: #B9BDC3;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 2.4px;
}
.countdown {
  display: flex;
  margin-top: 72px;
  gap: 48px;
  align-items: center;
}
.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #B9BDC3;
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 2.4px;
}
.time-unit > span {
  color: #FFF;
  font-family: Manrope;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 6.4px;
}
.line {
  width: 1px;
  background: #FFF;
  height: 24px;
}

@media (max-width:1240px){
  .content{
    max-width: 768px;
    position: relative;
  }
  .img-1{
    position: absolute !important;
    top: 0;
     right: 200px;;
  }
  .img-2{
    position: absolute !important;
    top: 0;
     right: -150px;;
  }
  .img-2{
    position: absolute !important;
    top: 0;
    right: 0;;
    left: 400px;
  }
}

@media (max-width: 768px) {
  .content {
    width: 90%;
  }
  .content > h1 {
    font-size: 36px;
    margin-top: 330px;
  }
  .content > p {
    font-size: 18px;
  }
  .countdown {
    gap: 24px;
    margin-top: 36px;
  }
  .time-unit {
    font-size: 18px;
  }
  .time-unit > span {
    font-size: 48px;
  }
  .line {
    height: 18px;
  }
}

@media (max-width: 480px) {
  .logo {
    gap: 8px;
  }
  .logo > h2 {
    font-size: 18px;
  }
  .content > h1 {
    font-size: 24px;
    margin-top: 240px;
  }
  .content > p {
    font-size: 14px;
  }
  .countdown {
    gap: 12px;
    margin-top: 18px;
  }
  .time-unit {
    font-size: 14px;
  }
  .time-unit > span {
    font-size: 32px;
  }
  .line {
    height: 12px;
  }
}

@media (max-width: 320px) {
  .logo-icon svg {
    width: 30px;
    height: 30px;
  }
  .logo > h2 {
    font-size: 14px;
  }
  .content {
    width: 100%;
  }
  .content > h1 {
    font-size: 20px;
    margin-top: 50px;
  }
  .content > p {
    font-size: 12px;
  }
  .countdown {
    gap: 8px;
    margin-top: 10px;
  }
  .time-unit {
    font-size: 10px;
  }
  .time-unit > span {
    font-size: 24px;
  }
  .line {
    height: 8px;
  }
  .img-1, .img-2, .img-3, .img-4, .img-5, .img-6 {
    width: 50%;
    height: 50%;
  }
}
</style>
